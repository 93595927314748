import React from 'react';
import { useDispatch } from 'react-redux';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import { makeGuess, setActive } from './game_state'
const keyboardLayout = {
    default: [
        "Q W E R T Y U I O P",
        "A S D F G H J K L",
        "{bksp} Z X C V B N M {enter}"
    ]
}

const keyboardDisplay = {
    "{enter}": "Enter",
    "{bksp}": "⌫",
}


let buffer = ""

export function GameKeyboard() {
    const dispatch = useDispatch();
    var buttonTheme = [
        {
            class: "correct",
            buttons: " ",
        },
        {
            class: "present",
            buttons: " ",
        },
        {
            class: "missing",
            buttons: " ",
        },
    ]

    function keyPressed(key: string) {
        console.debug("keypress", key, buffer)
        if (key === "{bksp}") {
            buffer = buffer.slice(0, -1)
            dispatch(setActive(buffer))
        } else if (key === "{enter}") {
            // TODO(lexton): logic from makeGuess needs to come here before the dispatch so we don't reset the buffer too early
            dispatch(makeGuess())
            buffer = ""
        } else if (buffer.length < 5) {
            buffer += key
            dispatch(setActive(buffer))
        }

    }

    return (
        <Keyboard layout={keyboardLayout} display={keyboardDisplay} onKeyPress={keyPressed} buttonTheme={buttonTheme}>
            {JSON.stringify(buttonTheme)}
        </Keyboard>
    )
}
export default GameKeyboard