import React from 'react';
import { Game } from './features/game/game';
import styles from './app.module.css';

function App() {
  return (
    <div className={styles.app}>
      <Game />
    </div>
  );
}

export default App;
