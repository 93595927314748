import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { evaluate, pre_clear_evaluation } from './evaluate'

export const MAX_WORDS = 6
export const MAX_CHARS = 5

interface GameState {
    board: string[]
    evaluations: string[]
    solution: string
    index: number
}

const initialState: GameState = {
    board: new Array(MAX_WORDS).fill(" ".repeat(MAX_CHARS)),
    evaluations: new Array(MAX_WORDS).fill(" ".repeat(MAX_CHARS)),
    solution: "",
    index: 0,
};

export const gameState = createSlice({
    name: 'game',
    initialState,
    reducers: {
        setSolution: (state, action: PayloadAction<string>) => {
            state.solution = action.payload
        },
        setActive: (state, action: PayloadAction<string>) => {
            const word = action.payload.toUpperCase().padEnd(MAX_CHARS, " ")
            state.board[state.index] = word
        },
        makeGuess: (state) => {
            if (state.index >= MAX_WORDS) {
                console.info("Too many guesses")
            } else if (pre_clear_evaluation(state.board[state.index].trim()) !== "") {
                console.info("Didn't pre-clear the word", state.board[state.index], state.index)
            } else {
                state.evaluations[state.index] = evaluate(state.board[state.index], state.solution).join('')
                state.index += 1
            }
        },
    },
});

export const { makeGuess, setActive, setSolution } = gameState.actions;

export const selectBoard = (state: RootState) => state.game.board;
export const selectEvaluations = (state: RootState) => state.game.evaluations;
export const selectSolution = (state: RootState) => state.game.solution;

export default gameState.reducer; 