import React, { useEffect } from 'react';
import GameKeyboard from "./keyboard";
import Gameboard from "./board"
import { useDispatch, useSelector } from 'react-redux';
import { selectSolution, setSolution } from './game_state';
import styles from './game.module.css';
import fetchTodaysWord from './client';

export function Game() {
    // setSolution(fetchTodaysWord())
    const solution = useSelector(selectSolution);
    
    const dispatch = useDispatch()

    fetchTodaysWord().then(
        val => dispatch(setSolution(val)),
        err => console.error("Error fetching todays word", err)
    )

    return (
        <div className={styles.game}>
            <p>{solution}</p>
            <Gameboard />
            <GameKeyboard />
        </div>
    );
}
