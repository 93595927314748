import { InWordList } from "./words"
import { MAX_CHARS } from "./game_state"
export function evaluate(word: string, solution: string): string[] {
    word = word.toUpperCase()
    solution = solution.toUpperCase()
    var results: string[] = Array(5).fill("m")
    var count: Record<string, number> = {};
    solution.split('').forEach(function (s) {
        count[s] ? count[s]++ : count[s] = 1;
    });


    for (var i = 0; i < MAX_CHARS; i += 1) {
        if (word[i] === solution[i]) {
            results[i] = "c"
            if (count[word[i]] > 0) {
                count[word[i]] -= 1
            }
        } else if (word[i] in count) {
            if (count[word[i]] > 0) {
                results[i] = "p"
                count[word[i]] -= 1
            }
        }
    }

    console.info(`evaluate(${word},${solution}) = ${results}`)

    return results
}

export function pre_clear_evaluation(word: string): string {
    word = word.toUpperCase().trim()
    console.info(`Guess: ${word}`)
    if (word.length !== MAX_CHARS) {
        return "Too short"
    } else if (!InWordList(word.toUpperCase())) {
        return "'" + word + "' Not a valid word."
    }
    return ""
}