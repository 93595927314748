import React from 'react'
import { useSelector } from 'react-redux';
import { selectBoard, selectEvaluations } from './game_state';
import styles from './game.module.css';


interface RowInterface {
    word: string
    evaluation: string
}

interface TileInterface {
    letter: String
    evaluation?: string
}

function Gameboard() {

    const board = useSelector(selectBoard)
    const evaluations = useSelector(selectEvaluations)

    console.debug(board, evaluations)

    function RowElem(prop: RowInterface) {
        console.debug("creating row", prop, prop.word, prop.evaluation)
        const c_word = [...prop.word]
        return (
            <div className={styles.row}>
                {c_word.map((letter: string, i: number) => <TileElem letter={letter} evaluation={prop.evaluation[i]} key={i} />)}
            </div>
        )
    }

    function TileElem(prop: TileInterface) {
        var className = styles.tile
        switch (prop.evaluation) {
            case "m":
                className += ` ${styles.missing}`
                break;
            case "p":
                className += ` ${styles.present}`
                break;
            case "c":
                className += ` ${styles.correct}`
                break;
            default:
                break

        }
        return <div className={className}>{prop.letter}</div>;
    }

    return (
        <div className={styles.gameBoard}>
            {board.map((word: string, i: number) => <RowElem word={word} evaluation={evaluations[i]} key={i} />)}
        </div>
    )
}

export default Gameboard