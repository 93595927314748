import GameNumber from './number'
import { WordList } from './word_list'
const WORD_CHOOSER = [
    1,
    5,
    10,
    11,
    12,
    13,
    14,
    15
]

function RandomWord(): string {
    const n = Math.floor(Math.random() * (WordList.length))
    console.debug("RandomWord", n, WordList[n])
    return WordList[n]
}

function TodaysWord(): string {
    var ndx = GameNumber()
    var word = RandomWord()
    if (ndx <= WORD_CHOOSER.length) {
        word = WordList[WORD_CHOOSER[ndx]]
    }
    console.info("todays word", "game", ndx, word)
    return word
}

function InWordList(word: string): boolean {
    return (WordList.indexOf(word) >= 0)
}


export { InWordList, TodaysWord, RandomWord }